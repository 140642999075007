function mergeForm(source, target) {
  // traverse the keys in source object, if key not found in target or with different type, drop it, otherwise:
  // 1. Use object merge if value is object (Can go deeper inside the object and apply same rule on all its properties)
  // 2. Use array merge if value is array (Extend the array item from source, but keep the obj format of target)
  // 3. Assign the value in other case (For other type, no need go deeper, assign directly)

  for (const key in source) {
    let value = source[key]
    const targetValueType = typeof target[key]
    const sourceValueType = typeof value

    // if key not found in target or type not match
    if (targetValueType === 'undefined' || targetValueType !== sourceValueType) {
      continue   // property not found in target or type not match
    }
    // for both type in object, need additional check
    else if (targetValueType === 'object' && sourceValueType === 'object') {
      // if value in target is null, assign any value from source to target, ignore format
      if (target[key] === null) {
        target[key] = source[key]
      }
      // if value in target is array, merge the item in source to target using the format of target only if source value is array
      else if (Array.isArray(target[key]) && Array.isArray(value)) {
        target[key] = mergeArray(value, target[key])
        
      }
      // if value in target is 'real' object (not null or array)', use object merge to do recurring merge, keep target format
      else if (!Array.isArray(target[key])){
        if (!Array.isArray(value) && value !== null) {
          mergeForm(value, target[key])
        }
      }
    }
    // if target value and source value has same type but not object, assign directly
    else if (targetValueType === sourceValueType) {
      target[key] = value
    }
  }
}

function mergeArray(sourceArray, targetArray) {
  // the rule of array merge need additional declare, assume the target already have values or objects in save format in the property<Array>, 
  // otherwise will not merge item from source to target since cannot add item property, 
  // NOTE: the item in target array will be totally overwrite instead of append on the tail, only the format will be keep, 
  // so the lenth of this property will same as source, below is a example:

  // target = [{a: 1, b: 2}, {a: 3, b: 4}] // Must in same format, otherwise the first one will be standard
  // source = [{a: 5, b: 6, c: 7}]
  // mergeArray(source, target) => [{a: 5, b: 6}]  // use format of target, but data from source

  // double check both of values are array
  if (!Array.isArray(sourceArray) || !Array.isArray(targetArray)) {
    return
  }
  // if target array is empty, don't push data in, since format is empty
  if (targetArray.length === 0) {
    return
  }

  let resultArray = []   // array to save the result
  let targetFormat = targetArray[0]
  let targetArrayType = typeof targetArray[0]
  

  // assign value from source to target, if item in target array is not object
  if (targetArrayType !== 'object'){
    sourceArray.forEach((value) => {
      // assign value directly if the type matched
      if (targetArrayType === typeof value) {
        resultArray.push(value)
      }
    })
  }
  // if the item in target is null, push anything in source to target (accept any format)
  else if (targetArray[0] === null) {
    sourceArray.forEach((value) => {
      resultArray.push(value)
    })
  }
  // if the item in target is array, drop it (the meaning of merge 2-d array to a 2-d array is not clear, so skip the situation)
  else if (!Array.isArray(targetArray[0])){
    // the item is a 'real' object, do object merge based on format of first item of target array
    sourceArray.forEach((value) => {
      mergeForm(value, targetFormat)   // data in targetFormat keep changing, so need to save a independent copy to the result
      resultArray.push(JSON.parse(JSON.stringify(targetFormat)))
    })
  }
  else {
    console.info('2-d array will be skipped')
  }

  // replace the value of target with newly build array (Assign result to target array will not work, must assign outside)
  return resultArray
}

const createShipmentForm = {
  used: false,
  lastEditOn: 0,
  origin_address: {
    line_1: "",
    line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country_alpha2: "US",
    contact_name: "",
    company_name: "",
    contact_phone: "",
    contact_email: ""
  },
  sender_address: {
    line_1: "",
    line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country_alpha2: "US",
    contact_name: "",
    company_name: "",
    contact_phone: "",
    contact_email: ""
  },
  return_address: {
    line_1: "",
    line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country_alpha2: "US",
    contact_name: "",
    company_name: "",
    contact_phone: "",
    contact_email: ""
  },
  destination_address: {
    line_1: "",
    line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country_alpha2: "US",
    contact_name: "",
    company_name: "",
    contact_phone: "",
    contact_email: ""
  },
  metadata: {},
  set_as_residential: false,
  consignee_tax_id: null,
  eei_reference: null,
  incoterms: "DDU",
  insurance: {
    is_insured: false,
    insured_amount: null,
    insured_currency: "USD"
  },
  order_data: {
    platform_name: null,
    platform_order_number: null,
    order_tag_list: [],
    seller_notes: null,
    buyer_notes: null
  },
  courier_selection: {
    selected_courier_id: null,
    allow_courier_fallback: false,
    apply_shipping_rules: true
  },
  shipping_settings: {
    units: {
      weight: "lb",
      dimensions: "in"
    },
    printing_options: {
      format: "pdf",
      label: "4x6",
      commercial_invoice: "A4",
      packing_slip: "none"
    },
    buy_label: false,
    buy_label_synchronous: false
  },
  parcels: [
    {
      total_actual_weight: null,
      box: {
        slug: '',
        length: '',
        width: '',
        height: '',
        name: 'Custom Box'
      },
      items: [
        {
          description: "",
          category: "",
          sku: "",
          quantity: 1,
          dimensions: {
            length: null,
            width: null,
            height: null
          },
          actual_weight: "",
          declared_currency: "USD",
          declared_customs_value: ''
        }
      ]
    }
  ]
}

const getRatesForm = {
  origin_address: {
    line_1: "No Address",
    line_2: "No Address",
    state: "xx",
    city: "NO CITY",
    postal_code: "00000",
    country_alpha2: "US"
  },
  destination_address: {
    line_1: "No Address",
    line_2: "No Address",
    state: "xx",
    city: "No City",
    postal_code: "000000",
    country_alpha2: "US"
  },
  incoterms: "DDU",
  insurance: {
    is_insured: false,
    insured_amount: null,
    insured_currency: "USD"
  },
  courier_selection: {
    apply_shipping_rules: true
  },
  shipping_settings: {
    units: {
      weight: "lb",
      dimensions: "in"
    },
    output_currency: "USD"
  },
  parcels: [
    {
      total_actual_weight: null,
      box: {
        slug: '',
        length: 0,
        width: 0,
        height: 0
      },
      items: [
        {
          description: "No Description",
          category: "",
          sku: "No SKU",
          quantity: 1,
          dimensions: {
            length: null,
            width: null,
            height: null
          },
          actual_weight: '',
          declared_currency: "USD",
          declared_customs_value: 1
        }
      ]
    }
  ]
}

export const Form =  {
  mergeForm: mergeForm,
  createShipment: createShipmentForm,
  getRates: getRatesForm
}

