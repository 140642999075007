import { initializeApp } from 'firebase/app'
import { Form } from "./shipForm"

import {
  getFirestore, doc, setDoc, getDocs, getDoc, addDoc, updateDoc,
  onSnapshot,
  collection, query, where, orderBy
}
  from 'firebase/firestore'

import {
  getAuth, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, updateProfile,
  onAuthStateChanged, signOut, sendPasswordResetEmail,
  useDeviceLanguage, setPersistence, browserSessionPersistence
}
  from 'firebase/auth'

import { getAnalytics, logEvent } from "firebase/analytics";

import is from 'is_js'

console.info = function () {}
console.warn = function () {}

const firebaseConfig = {
  apiKey: "AIzaSyDqkppq86Vnp3sbLo0cVyEMRLNKd-_WP64",
  authDomain: "easyship-v1.firebaseapp.com",
  projectId: "easyship-v1",
  storageBucket: "easyship-v1.appspot.com",
  messagingSenderId: "1042684860967",
  appId: "1:1042684860967:web:d8dac5cda5bae0b1344c02",
  measurementId: "G-77P9N04WG2"
}



initializeApp(firebaseConfig)
console.info('API: Firebase initialize Done')
const auth = getAuth()
const db = getFirestore()
const analytics = getAnalytics();

const forceEmulator = false
const specificLocalIp = ''
useDeviceLanguage(auth)
setPersistence(auth, browserSessionPersistence);

// ! Below is testing only code, comment it out before real deploy build
// import {connectFirestoreEmulator } from 'firebase/firestore'
// import {connectAuthEmulator } from 'firebase/auth'
// if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || forceEmulator) {
//   connectAuthEmulator(auth, `http://${ specificLocalIp || location.hostname }:9099`)
//   connectFirestoreEmulator(db, (specificLocalIp || location.hostname), 6001)
// }
// End testing code

const firebaseListener = {
  auth: null,
  user: null,
  allUsers: null,

  allShipments: null,
  draftShipment: null,
  ratesQuote: null,
  pickupQuote: null,
  chargeForShipment: null,
  chargedShipment: null,
  chargeHistory: null,
  paymentHistory: null
}
let warningSender = null


export function initWarningSender(sender) {
  warningSender = sender
}

let loginStamp = 0
let pendingUserDataUpdate = true

// ! Analytics API
export function logAppEvent(eventName, data) {
  logEvent(analytics, eventName, data)
}

// ! User Related API
export function initAuth(callback) {
  firebaseListener.auth = onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      console.info('API/initAuth: User Signed In, Listen to User Data')
      sendGlobalWarning('User Signed')

      loginStamp = Date.now()
      const userRef = doc(db, 'Users', currentUser.uid)

      firebaseListener.user = onSnapshot(userRef, { includeMetadataChanges: true }, (userSnapshot) => {
        if (userSnapshot.exists() && !userSnapshot.metadata.fromCache) {
          let userData = userSnapshot.data()
          // For new user, user data not ready instantly after auth change, so wait data ready then update additional user data
          if (pendingUserDataUpdate) {
            updateDoc(userRef, { name: currentUser.displayName, lastLoginOn: loginStamp })
            console.info('Updating User Profile')
            pendingUserDataUpdate = false
          } else {
            callback(userData)  // user profile updated, no pending change on user, start initialize process (to avoid frequently restart init process)
          }
          callback(userData)
          console.info('API/initAuth: currentUser UPDATE detected')
        } else {
          console.info('API/initAuth: Auth valid, but no related user data found yet')
          callback(null)
        }
      })

    }
    else {
      console.info('API/initAuth: User Signed Out')
      pendingUserDataUpdate = true
      detachDataListener()
      callback(null)
    }
  })
}

export function signIn(signInInfo, callback) {
  const result = {
    success: false,
    message: ''
  }
  switch (signInInfo.type) {
    case 'email':
      if (signInInfo.email === '' || signInInfo.password === '') {
        result.message = {
          en: 'Please input email/password',
          zh: '请输入邮箱/密码'
        }
        callback(result)
        return
      }
      signInWithEmailAndPassword(auth, signInInfo.email, signInInfo.password).then((userCredential) => {
        console.info('API/signIn: User Sign In Success')
        result.success = true
        callback(result)
      }).catch(error => {
        console.warn('API/signIn ', error.code)
        let errorCode = error.code
        if (errorCode == 'auth/user-disabled') {
          result.message = {
            en: 'Sorry, this account is suspend',
            zh: '抱歉,该账户已被禁用'
          }
        } else if (errorCode == 'auth/invalid-email') {
          result.message = {
            en: 'Please use a valid email address',
            zh: '请输入正确的邮箱地址'
          }
        } else if (errorCode == 'auth/user-not-found') {
          result.message = {
            en: 'Account with this email is not found',
            zh: '此邮箱地址尚未注册'
          }
        } else if (errorCode == 'auth/wrong-password') {
          result.message = {
            en: 'The password/email is incorrect',
            zh: '邮箱地址或密码错误'
          }
        } else {
          result.message = {
            en: 'Sorry, something weird happen, please try later',
            zh: '网络异常,请稍后再试'
          }
        }
        callback(result)
      })
      break;
    default:
      result.message = 'API/signIn: Unknown sign in type'
      console.error(result.message)
      callback(result)
      break;
  }
}

export function signUp(signUpInfo, callback) {
  const result = {
    success: false,
    message: ''
  }
  switch (signUpInfo.type) {
    case 'email':
      if (!signUpInfo.email || !signUpInfo.password === '' || !signUpInfo.name) {
        result.message = {
          en: 'Sorry, all fields are required',
          zh: '请提供所需的信息'
        }
        callback(result)
        return
      }
      if (is.not.email(signUpInfo.email)) {

        result.message = {
          en: 'Please use a valid email address',
          zh: '请输入正确的邮箱地址'
        }
        callback(result)
        return
      }
      createUserWithEmailAndPassword(auth, signUpInfo.email, signUpInfo.password).then((userCredential) => {
        updateProfile(userCredential.user, { displayName: signUpInfo.name })
        result.success = true
        console.info('API/signUp: User sign up success')
        callback(result)
      }).catch(error => {
        console.warn('API/signUp:', error.code)
        let errorCode = error.code
        if (errorCode == 'auth/email-already-in-use') {
          result.message = {
            en: 'This email address already in use',
            zh: '该邮箱地址已被注册'
          }
        } else if (errorCode == 'auth/invalid-email') {

          result.message = {
            en: 'Please use a valid email address',
            zh: '请输入正确的邮箱地址'
          }
        } else if (errorCode == 'auth/weak-password') {

          result.message = {
            en: 'Your password is too simple (At least 6 Characters)',
            zh: '密码过短,请使用6位以上密码'
          }
        } else {
          result.message = {
            en: 'Sorry, something weird happen, please try later',
            zh: '网络异常,请稍后再试'
          }
        }

        callback(result)
      })
      break;
    default:
      result.message = 'API/signUp: Unknown sign up type'
      console.error(result.message)
      callback(result)
      break;
  }
}

export function logOut() {
  detachDataListener()
  signOut(auth).then(() => {
    console.info('API/logOut: User Logout Success!')
  }).catch(error => {
    console.error('API/logOut: ', error)
  })
}

export function sendResetEmail(email, callback) {
  const options = {
    handleCodeInApp: false,
    url: `${location.protocol}//${location.hostname}:${location.port}/#/PasswordResetSuccess`
  }
  sendPasswordResetEmail(auth, email, options).then(() => {
    callback({
      success: true,
      message: {
        en: 'A password reset email has been sent to you!',
        zh: '密码重置邮件已发送,请检查您的邮箱执行后续操作'
      }
    })
  }).catch(error => {
    console.warn('API/sendPasswordResetEmail: ', error)
    callback({
      success: false,
      message: {
        en: 'Something wired happen, please check your email address or try again later',
        zh: '发送失败,请检查您的邮箱地址或稍后再试'
      }
    })
  })
}

export function listenToAllUsers(callback) {
  console.info('API/listenToAllUsers: Listen to all users')
  const userListRef = collection(db, 'Users')
  const userListQuery = query(userListRef, orderBy('isAdmin', 'desc'), orderBy('name', 'asc'))
  firebaseListener.allUsers = onSnapshot(userListQuery, (userListSnap) => {
    console.info('API/listenToAllUsers: User list update detected')
    let userList = [];
    if (!userListSnap.empty) {
      const userSnapArray = userListSnap.docs
      userList = userSnapArray.map(userSnap => {
        return userSnap.data()
      })
    }
    callback(userList)
  })
}

export async function sendHelpRequest(message, callback) {
  if (!message || !message.email || !message.uid || !message.message) {
    console.error('API/SendMessage: All message fields are required: [email, uid, message]')
    callback(false)
    return
  }
  addDoc(collection(db, 'HelpRequests'), {
    user: message.uid,
    message: message.message,
    email: message.email,
    name: message.name
  }).then(() => {
    callback(true)
  }).catch(error => {
    console.error('API/SendMessage: ', error)
    callback(false)
  })

}

// ! Shipment related API
export function listenToAllShipments(user, callback) {
  if (!user || !user.uid) {
    console.error('API/listenToAllShipments: User parameter is null, allShipmentList set to empty')
    callback([])
    return
  }
  const uid = user.uid
  let shipmentsQuery = null;

  if (user.isAdmin) {

    console.info("API/listenToAllShipments: Listen to all user's shipments");
    shipmentsQuery = query(
      collection(db, "Shipments"),
      where("deleted", "==", false),
      where("labelPaid", "==", true),
      // Services.orderBy("deleted", "desc"),   // For Emulator only
      orderBy("paidOn", "desc")
    );
  } else {
    console.info("API/listenToAllShipments: Listen to current user's shipments");
    shipmentsQuery = query(
      collection(db, "Shipments"),
      where("deleted", "==", false),
      where("user", "==", uid),
      where("labelPaid", "==", true),
      // Services.orderBy("deleted", "desc"),   // For Emulator only
      orderBy("paidOn", "desc")
    );
  }

  firebaseListener.allShipments = onSnapshot(
    shipmentsQuery,
    (snapshot) => {
      console.info("API: Shipments Update Detected");
      const newShipmentList = [];
      const shipmentSnapArray = snapshot.docs;
      for (let i = 0; i < snapshot.size; i++) {
        let shipment = shipmentSnapArray[i].data();
        shipment.shipmentId = shipmentSnapArray[i].id;
        newShipmentList.push(shipment);
      }

      callback(newShipmentList)
    }
  );
}

export async function checkDraftShipment(callback) {
  let uid = auth.currentUser.uid;
  if (!uid) {
    console.warn("API/listenToDraftShipment: currentUser not exist or have no uid")
    return;
  }
  const draftRef = doc(db, `Users/${uid}/templates/draft`);
  const draftSnap = await getDoc(draftRef)

  if (draftSnap.exists() && !draftSnap.used) {
    console.info("API/listenToDraftShipment: Draft Shipment Found")
    callback({
      isNew: draftSnap.data().lastEditOn === 0,
      form: draftSnap.data()
    })
  } else {
    console.info("API/listenToDraftShipment: Draft Shipment NOT Found, Creating New")
    resetDraftShipment((draftShipment) => {
      callback(draftShipment)
    })
  }
}


export async function resetDraftShipment(callback) {
  let uid = auth.currentUser.uid;
  if (!uid) {
    console.warn(
      "API/discardDraftShipment: Unable to reset the draft, no current user or invalid uid"
    );
    return null
  }

  // when reset the user draft, also create a new quote
  let newQuote = {
    valid: false,
    user: uid,
  }
  const quoteRef = await addDoc(collection(db, 'RateQuotes'), newQuote).catch(e => {
    console.error('API/resetDraftShipment: Cannot create price quote while creating new draft')
  })
  const quoteId = quoteRef.id
  console.info('API/resetDraftShipment: New rate quote created: id=', quoteId)

  const draftRef = doc(db, `Users/${uid}/templates/draft`);
  let newShipmentForm = Form.createShipment;
  newShipmentForm.needQuote = false
  newShipmentForm.quoteId = quoteId
  await setDoc(draftRef, newShipmentForm).then(() => {
    console.info('API/resetDraftShipment: Reset draft shipment success')
  }).catch(e => {
    console.error('API/resetDraftShipment: Cannot create draft form on server', e)
  })

  callback({
    isNew: true,
    form: newShipmentForm
  })
}

export async function updateDraftShipment(shipmentForm, needQuote, callback) {
  let uid = auth.currentUser.uid
  if (!uid) {
    sendGlobalWarning('Sorry, something wired happen, please refresh or try again later')
    console.error('API/updateDraftShipment: No current user or uid invalid')
    callback(false)
  }
  console.info('API/updateDraftShipment: Updating remote draft');
  const draftRef = doc(db, `Users/${uid}/templates/draft`);
  await updateDoc(draftRef, { ...shipmentForm, needQuote, lastEditOn: currentTime() }).catch(
    (e) => {
      console.warn('API/updateDraftShipment:', e);
      callback(false)
    }
  );
  callback(true)
}

export async function listenToRateQuote(quoteId, callback) {
  // detach current listener
  if (firebaseListener.ratesQuote) {
    firebaseListener.ratesQuote()
  }
  if (!quoteId) {
    console.info("API/listenToRateQuote: No quote id provided, quote listener cleared");
  }

  console.info("API/listenToRateQuote: Now listen to RateQuote = ", quoteId);

  const quoteRef = doc(db, `/RateQuotes/${quoteId}`)
  firebaseListener.ratesQuote = onSnapshot(quoteRef, (quoteSnapshot) => {
    if (quoteSnapshot.exists() && quoteSnapshot.data().valid) {
      console.info("API/listenToRateQuote: Valid rates detected", quoteSnapshot.data());
      let rates = quoteSnapshot.data().rates;
      callback({
        rates: rates,
        error: ''
      })
    } else if (quoteSnapshot.exists() && quoteSnapshot.data().error) {
      console.warn("API/listenToRateQuote: Cannot find shipping solution, reason => ", quoteSnapshot.data().error);
      callback({
        rates: [],
        error: quoteSnapshot.data().error
      })
    } else {
      console.info("API/listenToRateQuote: Rates not valid anymore");
      callback({
        rates: [],
        error: ''
      })
    }
  })

}

export async function createShipmentOrder(courierId, callback) {
  if (!auth.currentUser || !auth.currentUser.uid) {
    console.error("API/createShipment: Unable to create shipment, no current user or invalid uid")
    callback({
      shipmentId: '',
      error: {
        en: 'Sorry, something wired happen, please refresh or try again later',
        zh: '抱歉, 系统异常, 请刷新页面并重试'
      }
    })
  }
  if (!courierId) {
    console.error("API/createShipment: Unable to create shipment, no courier id provided")
    callback({
      shipmentId: '',
      error: {
        en: 'Sorry, something wired happen, please refresh or try again later',
        zh: '抱歉, 系统异常, 请刷新页面并重试'
      }
    })
  }
  const newShipmentRef = await addDoc(collection(db, 'Shipments'), {
    user: auth.currentUser.uid,
    courierId: courierId
  }).catch(e => {
    console.error("API/createShipment: Unable to create shipment, cannot connect to server", e)
  })

  if (!newShipmentRef.id) {
    callback({
      shipmentId: '',
      error: {
        en: 'Unable to create shipment, please try again later',
        zh: '无法创建订单, 请重试'
      }
    })
  }
  callback({
    shipmentId: newShipmentRef.id,
    error: ''
  })
  return
}

export function listenToShipmentOrder(shipmentId, callback) {
  if (firebaseListener.shipmentOrder) {
    firebaseListener.shipmentOrder()
    firebaseListener.shipmentOrder = null
  }
  if (!shipmentId) {
    console.error('API/listenToShipmentOrder: No shipmentId')
    callback({
      shipment: null,
      error: {
        en: "Sorry, unable to create your order, please try again later",
        zh: '无法创建订单, 请重试'
      }
    })
  }
  firebaseListener.shipmentOrder = onSnapshot(doc(db, 'Shipments', shipmentId), (docSnap) => {
    let shipment = docSnap.exists() ? docSnap.data() : null
    if (shipment) {
      shipment.shipmentId = shipmentId
      callback({
        shipment: shipment,
        error: docSnap.data().hasError ? 
        {
          en: "Sorry, unable to create your order, please try again later",
          zh: '无法创建订单, 请重试'
        } : {
          en: '',
          zh: ''
        }
      })
    }
  })
}

export function listenToPickupQuote(courierId, callback) {
  if (firebaseListener.pickupQuote) {
    firebaseListener.pickupQuote()
    firebaseListener.pickupQuote = null
  }
  const currentTime = new Date().valueOf();
  firebaseListener.pickupQuote = onSnapshot(
    doc(db, "PickUpQuotes", courierId),
    (docSnap) => {
      if (
        docSnap.exists() &&
        currentTime - docSnap.data().updateOn < 15 * 60 * 1000
      ) {
        // Pickup slot info fresh for 15min, trigger update if need
        console.info(
          "API/listenToPickupQuote: Pick Up quote fresh, direct load",
          courierId
        );

        let pickUpData = docSnap.data();
        let pickupSlots = [];
        for (const [key, value] of Object.entries(pickUpData.pickup.slots)) {
          if (value.length == 0) {
            continue; // skip the data has no time slot (could exist in easyship return)
          }
          let timeSlot = [];
          value.forEach((time) => {
            timeSlot.push({
              start: time.min_time,
              end: time.max_time,
            });
          });
          pickupSlots.push({
            date: key,
            time: timeSlot,
          });
        }
        pickupSlots.sort((dateA, dateB) => {
          if (dateA.date < dateB.date) return -1;
          if (dateB.date > dateB.date) return 1;
          return 0;
        });
        pickUpData.pickup.slots = pickupSlots;
        callback(pickUpData)
      } else if (!docSnap.exists()) {
        // pickup slot info not found or expired, trigger a refresh on database
        console.info("API/listenToPickupQuote: Create new pick up quote", courierId);
        setDoc(doc(db, "PickUpQuotes", courierId), {
          updateOn: 0,
        });
      } else if (currentTime - docSnap.data().updateOn >= 15 * 60 * 1000) {
        console.info("API/listenToPickupQuote: Trigger pick up quote refresh, courierId =", courierId);
        updateDoc(
          doc(db, "PickUpQuotes", courierId),
          { updateOn: 0 }
        );
      }
    }
  );
}

export async function cancelShipment(shipmentId) {
  let userSnap = await getDoc(doc(db, 'Users', auth.currentUser.uid))
    let userData = userSnap.data()
    let cancelRequest = {
      requestBy: userData.isAdmin ? 'admin' : auth.currentUser.uid,
      createOn: new Date().valueOf(),
      shipmentId: shipmentId || '',
    }
    const cancelRequestRef = await addDoc(collection(db, 'CancelRequests'), cancelRequest)

    return new Promise((resolve, reject) => {
      let cancelRequestListener = onSnapshot(cancelRequestRef, (requestSnap) => {
        if (requestSnap.data().success) {
          cancelRequestListener()
          resolve(true)
        } else if (requestSnap.data().fail){
          cancelRequestListener()
          reject(requestSnap.data().error)
        }
      })
    })
}

// ! Payment related API
export function listenToTopupPlan(callback) {
  const topupPlanCollection = collection(db, "TopupPlan");
  const topupPlanQuery = query(
    topupPlanCollection,
    where("active", "==", true)
  );

  firebaseListener.topupPlan = onSnapshot(
    topupPlanQuery,
    async (snapshot) => {
      const topupPlanList = [];
      const topupSnapArray = snapshot.docs;
      for (let i = 0; i < snapshot.size; i++) {
        let plan = topupSnapArray[i].data();
        plan.id = topupSnapArray[i].id;
        // Read price of plan in sub-collection (Only one price is allowed, the last in array will be use)
        const priceCollection = collection(topupSnapArray[i].ref, "prices");
        const priceSnap = await getDocs(priceCollection);
        priceSnap.forEach((doc) => {
          plan.price = doc.data();
          plan.price.id = doc.id;
        });
        topupPlanList.push(plan);
      }
      topupPlanList.sort((planA, planB) => {
        let numberPattern = /\d+/g;
        let planAValue = planA.name.match(numberPattern)
        let planBValue = planB.name.match(numberPattern)
        if (!planAValue) {
          return -1
        } else if (!planBValue) {
          return 1
        } else {
          return parseInt(planAValue[0]) - parseInt(planBValue[0])
        }
      })
      // save latest copy to local store for topup page to user
      window.localStorage.setItem('topupPlanList', JSON.stringify(topupPlanList))
      console.info("API: Topup Plan UPDATED");
      callback(topupPlanList)
    }
  );
}

export async function topupCheckout(plan, callback) {
  // support function work with stripe and firestore 
  // (Example: https://github.com/stripe-samples/firebase-subscription-payments/blob/master/public/javascript/app.js)

  // Handle Checkout
  if (!plan) {
    callback({
      success: false,
      warning: {
        en: 'No topup plan selected',
        zh: '请选择充值金额'
      },
      message: {
        en: '',
        zh: ''
      }
    })
    return
  }

  // start async operations, set loading message
  callback({
    success: false,
    warning: {
      en: '',
      zh: ''
    },
    message: {
      en: 'Processing, please wait...',
      zh: '处理中,请勿关闭页面...'
    }
  })

  // For all other prices we set quantity to 1.
  let selectedPrice = plan.price;
  if (!selectedPrice.recurring) {
    selectedPrice.quantity = 1;
    selectedPrice.amount = selectedPrice.unit_amount;
    selectedPrice.name = plan.name;
  }
  const checkoutSession = {
    automatic_tax: false,
    tax_id_collection: false,
    collect_shipping_address: false,
    allow_promotion_codes: false,
    line_items: [selectedPrice],
    success_url: `${window.location.protocol}//${window.location.host}/#/PaymentSuccess`, // Use dynamic path for easy debug on local
    cancel_url: `${window.location.protocol}//${window.location.host}/#/PaymentFail`,     // Use dynamic path for easy debug on local
    metadata: {
      key: "value",
    },
  };
  // For one time payments set mode to payment.
  if (selectedPrice.type === "one_time") {
    checkoutSession.mode = "payment";
    checkoutSession.payment_method_types = ["card"];
  }
  // In case user not existed anymore, so fail operation
  const userDoc = doc(db, "Users", auth.currentUser.uid);
  const userSnap = await getDoc(userDoc);
  let userRef = null;
  if (userSnap.exists()) {
    userRef = userSnap.ref;
  } else {
    console.error("API/topupCheckout: User not existed anymore");
    callback({
      success: false,
      warning: {
        en: 'Sorry, but something wired happen, please try again later. If you has been charged, please contact us for help.',
        zh: '非常抱歉,充值未能成功,如造成扣款或需要任何帮助, 请立即通过帮助页面与我们联系, 我们将立即处理'
      },
      message: {
        en: '',
        zh: ''
      }
    })
    return
  }
  // ! remove unnecessary prop in checkout session because of error: 
  // "Received unknown parameters: active, billing_scheme, id, interval,
  // interval_count, product, recurring, tax_behavior, tiers, tiers_mode,
  // transform_quantity, trial_period_days, type, unit_amount"
  const {
    active, billing_scheme, id, interval, interval_count, product,
    recurring, tax_behavior, tiers, tiers_mode, description,
    transform_quantity, trial_period_days, type, unit_amount, ...simplifiedPriceData
  } = checkoutSession.line_items[0]

  checkoutSession.line_items[0] = simplifiedPriceData

  // push session data to cloud
  const checkoutSessionCollection = collection(
    userRef,
    "checkout_sessions"
  );
  console.info('API/topupCheckout: Upload checkout session data...', checkoutSession)
  const checkoutSessionRef = await addDoc(checkoutSessionCollection, checkoutSession)

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(checkoutSessionRef, (sessionSnap) => {
    const { error, url } = sessionSnap.data();
    console.info("API/topupCheckout: Updated session data...", sessionSnap.data());
    if (error) {
      console.error("API/topupCheckout: Error occurred during process checkout session...", error);
      callback({
        success: false,
        warning: {
          en: 'Sorry, but something wired happen, please try again later. If you has been charged, please contact us for help.',
          zh: '非常抱歉,充值未能成功,如造成扣款或需要任何帮助, 请立即通过帮助页面与我们联系, 我们将立即处理'
        },
        message: {
          en: '',
          zh: ''
        }
      })
      return
    }
    if (url) {
      console.info('API/topupCheckout: Checkout Session processed, redirect to stripe checkout')
      window.location.assign(url);
      callback({
        success: true,
        message: '',
        warning: ''
      })
      return
    }
  });
}

export async function createChargeForShipment(shipmentId, selectedPickupOption, callback) {
  if (!shipmentId) {
    console.error('API/createChargeForShipment: No shipmentId provided')
    callback({
      hasError: true,
      error: {
        en: "Sorry, unable to create your order, please try again later",
        zh: '订单创建失败, 请稍后重试'
      },
      shipmentId: ''
    })
    return
  }
  if (!selectedPickupOption) {
    console.error('API/createChargeForShipment: No selectedPickupOption provided')
    callback({
      hasError: true,
      error: {
        en: "Sorry, unable to create your order, please try again later",
        zh: '订单创建失败, 请稍后重试'
      },
      shipmentId: ''
    })
    return
  }
  try {
    // save the pickup options of the shipment
    await updateDoc(doc(db, 'Shipments', shipmentId), { pickupOption: selectedPickupOption }).catch(error => {
      throw 'Cannot save pickup option'
    })

    const newCharge = {
      user: auth.currentUser.uid,
      shipmentId: shipmentId,
      result: "created",
      pickupOption: selectedPickupOption,
      created: parseInt(Date.now() / 1000)
    }

    const chargeRef = await addDoc(
      collection(db, "Charges"),
      newCharge
    ).catch((e) => {
      throw e;
    })

    if (firebaseListener.chargeForShipment) firebaseListener.chargeForShipment()

    firebaseListener.chargeForShipment = onSnapshot(chargeRef, (chargeSnap) => {
      if (chargeSnap.exists() && chargeSnap.data().result == "success") {
        if (firebaseListener.chargeForShipment) {
          firebaseListener.chargeForShipment()
          firebaseListener.chargeForShipment = null
        }
        callback({
          error: '',
          shipmentId: chargeSnap.data().shipmentId
        })
        console.info('API/createChargeForShipment, charge shipment SUCCESS!')

      } else if (chargeSnap.exists() && chargeSnap.data().result == "fail") {
        if (firebaseListener.chargeForShipment) {
          firebaseListener.chargeForShipment()
          firebaseListener.chargeForShipment = null
        }

        throw chargeSnap.data().error
      }
    })

  } catch (error) {
    console.error('API/createChargeForShipment:', error)
    callback({
      error: {
        en: "Sorry, unable to create your order, please try again later",
        zh: '订单创建失败, 请稍后重试'
      },
      shipmentId: 'null'
    })
  }

}

export function listenToChargedShipment(shipmentId, callback) {
  if (!shipmentId) {
    console.error('API/createChargeForShipment: No shipmentId provided')
    callback({
      error: {
        en: "Sorry, unable to create your order, please try again later",
        zh: '订单创建失败, 请稍后重试'
      },
      shipment: null
    })
    return
  }

  if (firebaseListener.chargedShipment) firebaseListener.chargedShipment()

  firebaseListener.chargedShipment = onSnapshot(doc(db, 'Shipments', shipmentId), (shipmentSnap) => {
    callback({
      error: '',
      shipment: shipmentSnap.data()
    })
  })

}

export function listenToChargeHistory(callback) {
  if (!auth.currentUser) {
    callback([])
    return
  }
  const chargesQuery = query(
    collection(db, "Charges"),
    where("user", "==", auth.currentUser.uid),
    orderBy("created", "desc")
  )

  if (firebaseListener.chargeHistory) {
    firebaseListener.chargeHistory()
  }
  console.info('API: Listen to charge history')
  firebaseListener.chargeHistory = onSnapshot(chargesQuery, (chargesQuerySnap) => {
    const chargeSnaps = chargesQuerySnap.docs;

    let list = []
    for (let i = 0; i < chargeSnaps.length; i++) {
      if (chargeSnaps[i].data().result == 'success') {
        list.push({ ...chargeSnaps[i].data(), type: "charge" });
      }
    }
    callback(list)
  });

}

export function listenToPaymentHistory(callback) {
  if (!auth.currentUser) {
    callback([])
    return
  }
  const paymentsQuery = query(
    collection(db, "Payments"),
    where("user", "==", auth.currentUser.uid),
    orderBy("created", "desc")
  );

  if (firebaseListener.paymentHistory) {
    firebaseListener.paymentHistory()
  }
  console.info('API: Listen to payment history')
  firebaseListener.paymentHistory = onSnapshot(paymentsQuery, (paymentsQuerySnap) => {
    const paymentsSnaps = paymentsQuerySnap.docs;

    let list = []
    for (let i = 0; i < paymentsSnaps.length; i++) {
      if (paymentsSnaps[i].data().result == 'success') {
        list.push({ ...paymentsSnaps[i].data(), type: "payment" });
      }
    }
    callback(list)
  });
}

export async function getFullCreditHistory(user) {
  if (!user || !user.uid){
    console.warn('API/getFullHistory: No user provided')
    return []
  }
  const paymentsQuery = query(
    collection(db, "Payments"),
    where("user", "==", user.uid),
    orderBy("created", "desc")
  );
  const chargesQuery = query(
    collection(db, "Charges"),
    where("user", "==", user.uid),
    orderBy("created", "desc")
  )

  let paymentHistory = []
  let chargeHistory = []
  await Promise.all([getDocs(paymentsQuery), getDocs(chargesQuery)]).then(results => {
    const paymentsSnaps = results[0].docs;
    const chargeSnaps = results[1].docs;

    for (let i = 0; i < paymentsSnaps.length; i++) {
      if (paymentsSnaps[i].data().result == 'success') {
        paymentHistory.push({ ...paymentsSnaps[i].data(), type: "payment" });
      }
    }
    for (let i = 0; i < chargeSnaps.length; i++) {
      if (chargeSnaps[i].data().result == 'success') {
        chargeHistory.push({ ...chargeSnaps[i].data(), type: "charge" });
      }
    }

  })
  return chargeHistory.concat(paymentHistory).sort((recordA, recordB) => {
    return recordB.created - recordA.created;
  });

}

export async function adminTopup(data, callback) {
  if (!data.user) {
    window.alert(window.locale == 'en' ? 'Topup Failed, No user provided' : '充值失败，未指定用户')
    return
  }

  const newPayment = {
    created: parseInt(Date.now() / 1000),
    user: data.user,
    by: auth.currentUser.uid,
    amount: parseInt(data.creditInput || 0) * 100,
    amount_received: parseInt(data.creditInput || 0) * 100,
    result: 'created',
    remark: data.remark || 'Topup by Administrator',
    from: 'admin'
  }

  const paymentRef = await addDoc(collection(db, 'Payments'), newPayment)

  let paymentListener = onSnapshot(paymentRef, paymentSnap => {
    if (paymentSnap.data().result == 'success') {
      window.alert(`${window.locale == 'en' ? 'Add credit success, new credit = ' : '充值成功，当前余额 = '}${paymentSnap.data().creditAfter.toFixed(2)}`)
      paymentListener()
      callback()
    }
    if (paymentSnap.data().result == 'fail') {
      window.alert(`${window.locale == 'en' ? 'Add Amount failed, error message: ' : '充值失败，错误信息: '} ${paymentSnap.exists() ? paymentSnap.data().error : 'Cannot create payment'}`)
      paymentListener()
      callback()
    }
  })
}

export async function adminCharge(data, callback) {
  if (!data.user) {
    window.alert(window.locale == 'en' ? 'Topup Failed, No user provided' : '充值失败，未指定用户')
    return
  }
  const newCharge = {
    created: parseInt(Date.now() / 1000),
    user: data.user,
    by: auth.currentUser.uid,
    userTotal: parseFloat(data.creditInput),
    result: 'created',
    remark: data.remark || 'Charge by Administrator'
  }

  const chargeRef = await addDoc(collection(db, 'Charges'), newCharge)

  let chargeListener = onSnapshot(chargeRef, chargeSnap => {
    if (chargeSnap.data().result == 'success') {
      window.alert(`${window.locale == 'en' ? 'Charge credit success, new credit = ' : '扣款成功，当前余额 = '}${chargeSnap.data().creditAfter.toFixed(2)}`)
      chargeListener()
      callback()
    } 
    if (chargeSnap.data().result == 'fail') {
      vueInstance.buttonText = 'Submit'
      window.alert(`${window.locale == 'en' ? 'Charge Amount failed, error message: ' : '扣款失败，错误信息: '} ${chargeSnap.exists() ? chargeSnap.data().error : 'Cannot create charge'}`)
      chargeListener()
      callback()
    }
  })
}

// ! Global Data
export function listenToEasyshipGlobalData(callback) {
  const boxListRef = doc(db, `GlobalData/easyship_box_list`);
  const cateListRef = doc(db, `GlobalData/easyship_category_list`);
  let boxLastUpdate = currentTime() // default to time of first bind to skip first regular check
  let cateLastUpdate = currentTime()

  firebaseListener.boxList = onSnapshot(boxListRef, (boxListSnap) => {
    console.info('API/listenToEasyshipGlobalData: Update available easyship box list')
    if ( boxListSnap.exists() && boxListSnap.data().needUpdate === true) {
      console.info('API/listenToEasyshipGlobalData: Waiting for box list update')
    } else if (!boxListSnap.exists()) {
      setDoc(boxListRef, {
        needUpdate : true,
        updateOn: 0,
        boxes: []
      }).catch(e => console.error(e))
    } else if ((currentTime() - boxListSnap.data().updateOn) > (24 * 3600 * 1000)) {
      // set updateOn to 0 to trigger server side refresh of box list
      console.info('API/listenToEasyshipGlobalData: Box list expired, trigger update')
      updateDoc(boxListRef, {
        needUpdate: true
      })
    } else {
      boxLastUpdate = currentTime()
      console.info('API/listenToEasyshipGlobalData: Box list fresh, load available box')
      callback(boxListSnap.data().boxes, null)
    }
  })

  firebaseListener.cateList = onSnapshot(cateListRef, (catListSnap) => {
    console.info('API/listenToEasyshipGlobalData: Update available easyship Category list')
    if (catListSnap.exists() && catListSnap.data().needUpdate === true) {
      console.info('API/listenToEasyshipGlobalData: Waiting for Category list update')
    } else if (!catListSnap.exists()) {
      setDoc(cateListRef, {
        needUpdate : true,
        updateOn: 0,
        boxes: []
      }).catch(e => console.error(e))
    } else if ((currentTime() - catListSnap.data().updateOn) > (24 * 3600 * 1000)) {
      // set updateOn to 0 to trigger server side refresh of Category list
      console.info('API/listenToEasyshipGlobalData: Category list expired, trigger update')
      updateDoc(cateListRef, {
        needUpdate: true
      })
    } else {
      cateLastUpdate = currentTime()
      console.info('API/listenToEasyshipGlobalData: Category list fresh, load available Category')
      callback(null, catListSnap.data().categories)
    }
  })

  // Check if the data still fresh every hour
  setInterval(() => {
    if ((currentTime() - boxLastUpdate) > (24 * 3600 * 1000)) {
      console.info('API/listenToEasyshipGlobalData: Box list expired, trigger update')
      updateDoc(boxListRef, {
        updateOn: 0
      })
    }
    if ((currentTime() - cateLastUpdate) > (24 * 3600 * 1000)) {
      console.info('API/listenToEasyshipGlobalData: Category list expired, trigger update')
      updateDoc(cateListRef, {
        updateOn: 0
      })
    }
  }, 60 * 60 * 1000)


}


// ! Sup Functions
function detachDataListener() {
  console.warn('API: Detaching All Data Listeners')
  Object.keys(firebaseListener).filter(key => key !== 'auth').forEach((key) => {
    if (firebaseListener[key] !== null) {
      firebaseListener[key]()
      firebaseListener[key] = null
    }
  })
}

function sendGlobalWarning(message) {
  if (!warningSender) {
    console.error('API: Warning sender not initialized')
    return
  }
  if (typeof message == 'string') {
    warningSender(message)
  }
}

function currentTime() {
  return new Date().valueOf()
}