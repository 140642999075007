<template>
  <div>
    <LaunchScreen v-show="loading" />
    <router-view id="router-view"/>
  </div>
</template>

<script>
import LaunchScreen from "@/components/LaunchScreen.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    LaunchScreen,
  },
  created() {
    let localeSetting = window.localStorage.getItem("locale");
    if (!localeSetting) {
      window.localStorage.setItem("locale", this.$i18n.locale);
    } else {
      this.$i18n.locale = localeSetting;
    }
    window.locale = this.$i18n.locale;
  },
  data() {
    return {
      text: "G-Shipping",
      enterLoadingOn: 0,
      loading: false
    };
  },
  computed: {
    ...mapState("user", {
      startupLoading: "startupLoading",
    }),
  },
  watch: {
    startupLoading () {
      if (this.startupLoading) {
        this.loading = true
        enterLoadingOn = Date.now()
      } else {
        // when escape loading, at least show loading for 1 second
        let loadingRunningTime = Date.now() - this.enterLoadingOn
        if ( loadingRunningTime < 1000) {
          setTimeout(() => {
            this.startupLoading = 0
            this.loading = false
          }, 1000 - loadingRunningTime)
        } else {
          this.startupLoading = 0
          this.loading = false
        }
      }
    }
  }
};
</script>

<style>
.reset {
  background-color: #ffebee;
  color: #d32f2f;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>