export const routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
      priority: 1
    },
    {
      path: '/AllUsers',
      name: 'AllUsers',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AllUsers.vue'),
      priority: 0
    },
    {
      path: '/NewShipment',
      name: 'NewShipment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/NewShipment.vue'),
      priority: 0.8
    },
    {
      path: '/AllShipments',
      name: 'AllShipments',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AllShipments.vue'),
      priority: 0.8
    },
    {
      path: '/MyAccount',
      name: '/MyAccount',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/MyAccount.vue'),
      priority: 0.6
    },
    {
      path: '/MyPickups',
      name: '/MyPickups',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/MyPickups.vue'),
      priority: 0.6
    },
    {
      path: '/PaymentSuccess',
      name: '/PaymentSuccess',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/PaymentSuccess.vue'),
      priority: 0
    },
    {
      path: '/PaymentFail',
      name: '/PaymentFail',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/PaymentFail.vue'),
      priority: 0
    },
    {
      path: '/Topup',
      name: '/Topup',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Topup.vue'),
      priority: 0.5
    },
    {
      path: '/PasswordResetSuccess',
      name: '/PasswordResetSuccess',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/PasswordResetSuccess.vue'),
      priority: 0
    },
    {
      path: '/Help',
      name: '/Help',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/HelpCenter.vue'),
      priority: 0.5
    },
    {
      path: '/EULA',
      name: '/EULA',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/EULA.vue'),
      priority: 0
    },
    {
      path: '/PrivacyPolicy',
      name: '/PrivacyPolicy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'),
      priority: 0
    }
  ]