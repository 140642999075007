export default {
  "LeaveMessage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a Message"])},
    "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us a message for help or feedback, we will contact you through email ASAP."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "send_result": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your message, our team will contact you by email very soon."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, something went wrong, please try again later"])}
    },
    "warning": {
      "no_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter some words"])},
      "no_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address Not Found! Please login first"])},
      "too_frequent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You send message too frequently, please try again in a few seconds"])}
    },
    "placeholder": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your message here"])}
    }
  },
  "Credits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance: "])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "refund_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Refund or Question"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "EmptyState": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Your list is empty."])}
  },
  "FormStep1": {
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1 of 4: Receiver Information"])},
    "please_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check/fill in the terms marked in red."])},
    "before_start_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip & Instruction"])},
    "before_start_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- In case you need insurance for you shipment, please contact us so we can create your order for you"])},
    "before_start_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- At any moment you need help, please contact us and leave your contact information so we can help"])},
    "before_start_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- If you have problem while waiting for the result after submission (for example, if it is keep loading), please refresh your page and check your order status in All Shipments"])},
    "before_start_button_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "before_start_button_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "ship_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State/Province"])},
    "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])},
    "ship_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship To"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver Name"])},
    "residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential address"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next(2/4)"])}
  },
  "FormStep2": {
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2 of 4: Shipment Information"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(optional)"])},
    "please_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check/fill in the terms marked in red."])},
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "dimension_descrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box dimensions are used to calculate the shipping cost by some couriers. Fill them in accurately to avoid potential adjustment charges later."])},
    "box_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box Name"])},
    "box_dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box Dimensions"])},
    "box_dimension_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(For any dimension greater than 99, please input 99 instead)"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "choose_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one"])},
    "unit_selling_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Selling Price"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty."])},
    "unit_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Weight"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "add_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item"])},
    "add_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A New Box"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous(1/4)"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next(3/4)"])}
  },
  "FormStep3": {
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3 of 4: Choose A Courier"])},
    "list_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching For Best Rates"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "total_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time"])},
    "cheapest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheapest"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "free_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Pickup"])},
    "paid_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Pickup"])},
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop-off"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service options"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no solution found!"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous(2/4)"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next(4/4)"])},
    "please_select_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a courier"])}
  },
  "FormStep4": {
    "popup_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Your Order..."])},
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the Courier selected is not available at this moment"])},
    "your_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Choice"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Not available)"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service:"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Time: "])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Alternative"])},
    "new_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept New Courier"])},
    "choose_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Another"])},
    "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4 of 4: Summary"])},
    "your_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Courier: "])},
    "ship_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "ship_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship To"])},
    "list_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are creating your order..."])},
    "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
    "dropoff_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop-off/Pickup"])},
    "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient credit balance (current balance: "])},
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already top up? Your credit should be ready in a minute."])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous(3/4)"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])}
  },
  "Intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Money, Save Time"])},
    "newshipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Shipment"])},
    "promo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Shipping Couriers"])},
    "promo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Pickups Easily"])}
  },
  "LaunchScreen": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G-Shipping"])}
  },
  "MyPickupsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Pickups"])},
    "searchby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by "])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup ID"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "dateAtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date & Time"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "pickupID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup ID"])},
    "pickupaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Address"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "loadmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
    "filter_option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment ID"])},
    "filter_option_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "filter_option_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "filter_option_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "filter_option_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "filter_option_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "filter_option_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "filter_option_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "filter_option_pickup_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Address"])},
    "filter_option_pickup_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick ID"])},
    "filter_option_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
  },
  "MyShipmentsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Shipments"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "optionalfilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional filter"])},
    "searchby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by "])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "shipmentsno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Shipments"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost (ES)"])},
    "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "matches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("found")), " of ", _interpolate(_named("total")), " shipments found based on your search"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "IDdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID & Date"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "trackingno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking Number"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "loadmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
    "filter_option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "filter_option_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "filter_option_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "filter_option_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "filter_option_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "filter_option_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "filter_option_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "filter_option_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "Navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Users"])},
    "newshipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Shipment"])},
    "allshipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Shipments"])},
    "pickups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickups"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "switch_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])}
  },
  "PopupLoading": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
  },
  "ReviewShipment": {
    "empty-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, data for this shipment not exist anymore!"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Canceled"])},
    "cancel-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Cancel Failed"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping label: "])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
    "shipfrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship from:"])},
    "shipto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship to:"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier:"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup:"])},
    "notrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not request"])},
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropoff:"])},
    "findlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Drop-off Locations"])},
    "nolocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Drop-off Info"])},
    "shipmentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Fee:"])},
    "pickupfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Fee:"])},
    "totalfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "totalbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Box(es):"])},
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box"])},
    "itemcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Count:"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty."])},
    "unitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling your shipment, please wait..."])},
    "total_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Weight:"])},
    "item_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Weight"])},
    "confirm_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the shipment?"])}
  },
  "ServiceOptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Options"])},
    "provideby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provided by: "])},
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop-off"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop-off Locations"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule a Pickup"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Required"])},
    "fee-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated fee is $3.80, final charge determined by service provider."])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking For available slots of your courier"])}
  },
  "Signin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to create/manage shipments"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back..."])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Account"])}
  },
  "SubmitFail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OH NO! We were unable to create your shipment."])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment creation Failed. Please try again."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shipment"])}
  },
  "SubmitSuccess": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment Created!"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print your label"])},
    "print-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Please allow some time for your courier to prepare the shipment label. Once we receive the label, you can print it by click button below, or access it anytime in"])},
    "note-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Shipments"])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the shipment label from your courier"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview label"])},
    "generating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating"])},
    "send-package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Your Package"])},
    "send-package-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your package (with label) to dropoff location or waiting for the pick-up."])},
    "quick-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Links"])},
    "all-shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Shipments"])},
    "new-shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Shipment"])}
  },
  "UsersList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "search_place_holder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search by ", _interpolate(_named("term"))])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Balance"])},
    "last_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Order"])},
    "last_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Seen"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
    "timer": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 1 Year"])},
      "day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["~ ", _interpolate(_named("day")), " Days"])},
      "hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["~ ", _interpolate(_named("hour")), " Hours ago"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 1 Hour"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 1 Min"])}
    }
  },
  "AskDraftBar": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unfinished shipment"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume Edit"])},
    "start_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start New"])}
  },
  "ChangeCredit": {
    "edit_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Credit"])},
    "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Name: ", _interpolate(_named("name"))])},
    "email_address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email address: ", _interpolate(_named("email"))])},
    "current_credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current credit balance: $", _interpolate(_named("credit"))])},
    "add_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Credit"])},
    "sub_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtract Credit"])},
    "expect_credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expect credit balance after edit: $", _interpolate(_named("credit"))])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional) Remark"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "warning": {
      "no_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number"])},
      "number_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number larger than 0"])},
      "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User doesn't exists"])},
      "loading_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading User Data Error"])}
    },
    "button": {
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
    }
  },
  "CreateAccount": {
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Account"])},
    "to_easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to make shipments easier"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "button": {
      "to_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In to My Account"])},
      "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Your Account..."])},
      "start_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start My Shipping"])}
    },
    "agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using this software, you agree to the"])},
    "eula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End-User License Agreement"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
  },
  "CreditHistory": {
    "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
    "loading_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading user data"])},
    "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Topup: $", _interpolate(_named("amount"))])},
    "expend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expend: $", _interpolate(_named("amount"))])},
    "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
    "all_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Records Listed"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any Questions?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "no_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No History Found"])}
  },
  "AccountGeneral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "timer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can resend email in ", _interpolate(_named("time")), " seconds"])},
    "email_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address Not Found!"])}
  },
  "NoSolutionTips": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips that may help: "])},
    "tip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your ship-from and ship-to address and make sure they are correct and accurate"])},
    "tip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have multiple boxes, please try send only one box in one shipment"])},
    "tip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try use a custom box or a smaller box"])},
    "tip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try send a box with less total weight"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us a message!"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would like help?"])}
  },
  "AllShipments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Shipments - G-Shipping"])}
  },
  "AllUsers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Users - G-Admin"])}
  },
  "HelpCenter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help - G-Shipping"])}
  },
  "Home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home - G-Shipping"])}
  },
  "MyAccount": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account - G-Shipping"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])}
  },
  "MyPickups": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickups - G-Shipping"])}
  },
  "NewShipment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Shipment - G-Shipping"])}
  },
  "PasswordResetSuccess": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset Success!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can close this tab and login use your new password"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Update Success - G-Shipping"])}
  },
  "PaymentFail": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aaaah! We were unable to process your payment."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup Failed. Please close the tab and try again."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Fail - G-Shipping"])}
  },
  "PaymentSuccess": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment has been received!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credit should be available in 1 min. You can close this tab and continue on your shipments."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Success - G-Shipping"])}
  },
  "Topup": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much would you like to topup?"])},
    "please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an amount to topup."])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-up - G-Shipping"])},
    "seller_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Credit Sale by AM CENTER HOME"])}
  },
  "EULA": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EULA - G-Shipping"])}
  },
  "PrivacyPolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy - G-Shipping"])}
  }
}