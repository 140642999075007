import { initWarningSender, initAuth, signIn, logOut, signUp, sendResetEmail, listenToAllUsers, sendHelpRequest } from '../api'
import is from 'is_js'

export const user = {
  namespaced: true,
  state() {
    return {
      currentUser: null,
      signInWarning: '',
      signUpWarning: '',
      passwordResetMessage: '',
      allUserList: [],
      startupLoading: true,

      sendMessageResult: null
    }
  },
  getters: {
    isAdmin(state) {
      return state.currentUser ? state.currentUser.isAdmin : false
    }
  },
  mutations: {
    setUser(state, user) {
      if (!user) {
        state.currentUser = null
      } else {
        state.currentUser = user
      }
    },
    setSignInWarning(state, message) {
      state.signInWarning = message || ''
    },
    setSignUpWarning(state, message) {
      state.signUpWarning = message || ''
    },
    setPasswordReestMessage(state, message) {
      state.passwordResetMessage = message || ''
      // clear this message in 10s
      setTimeout(() => {
        state.passwordResetMessage = ''
      }, 10 * 1000)
    },
    setAllUserList(state, userList) {
      state.allUserList = userList || []
    },
    startUpDone(state) {
      state.startupLoading = false
    },
    setSendMessageResult(state, success) {
      state.sendMessageResult = { success : !!success}
    },
    clearSendMessageResult(state) {
      state.sendMessageResult = null
    }
  },
  actions: {
    initialize(context) {
      console.info('VUEX/User: Start initialization')

      // set callback function in API so it can send warning to APP
      initWarningSender((message) => {
        if (typeof message == 'string' && message) {
          context.commit('setGlobalWarning', message, {root: true})
        }
      })
      context.dispatch('payment/listenTopupPlan', {}, { root: true })


      initAuth((user) => {
        context.commit('setUser', user)
        // If user is exist, trigger rest initialize process
        if (user) {
          // todo: (Done) Reset initialization processor when user data ready
          context.dispatch('shipment/initialize', user, { root: true })
          context.dispatch('payment/initialize', user, { root: true })
          if (user.isAdmin) {
            context.dispatch('listenToAllUsers')
          }
        }
        context.commit('startUpDone')
      })
    },
    login(context, signInInfo) {
      signIn(signInInfo, (signInResult) => {
        if (!signInResult.success) {
          context.commit('setSignInWarning', signInResult.message[window.locale || 'en'])
        }
      })
    },
    signUp(context, signUpInfo) {
      signUp(signUpInfo, (signUpResult) => {
        if (!signUpResult.success) {
          context.commit('setSignUpWarning', signUpResult.message[window.locale || 'en'])
        }
      })
    },
    logOut(context) {
      logOut()
    },
    clearSignInWarning(context) {
      context.commit('setSignInWarning', '')
    },
    clearSignUpWarning(context) {
      context.commit('setSignUpWarning', '')
    },
    sendPasswordResetEmail(context, email) {
      if (!email || is.not.email(email)) {
        context.commit('setPasswordReestMessage', window.locale == 'en' ? "Please input a valid email address" : "请输入正确的邮箱地址")
        return
      }
      context.commit('setPasswordReestMessage', window.locale == 'en' ? "Sending password reset email..." : "正在发送密码重置邮件...")
      sendResetEmail(email, (sendResult) => {
        context.commit('setPasswordReestMessage', sendResult.message[window.locale || 'en'])
      })
    },
    listenToAllUsers(context) {
      listenToAllUsers((allUserList) => {
        context.commit('setAllUserList', allUserList)
      })
    },
    sendMessage(context, message) {
      sendHelpRequest(message, (sendResult) => {
        context.commit('setSendMessageResult', sendResult)
      })
    }
  }
}