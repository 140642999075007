import { listenToPickupQuote } from "../api"

export const quote = {
  namespaced: true,
  state () {
    return {
      pickupData: null,
      courierId: ''
    }
  },
  mutations: {
    setPickupData(state, pickupData) {
      state.pickupData = pickupData || null
    },
    setCourierId(state, courierId) {
      state.courierId = courierId || ''
    }
  },
  actions: {
    listenToPickupQuote(context, courierId) {
      context.commit('setCourierId', courierId)
      if (!courierId) {
        context.commit('setPickupData', null)
      } else{
        console.info('VUEX: Listen to new pickup Data of courier ID = ', courierId )
        listenToPickupQuote(courierId, (pickupData) => {
          context.commit('setPickupData', pickupData)
        })
      }
    }
  }
}