import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import { logAppEvent } from './api'

// console.info = function () {}
const VERSION = '1.0.1-Alpha Build 001'
window.appVersion = VERSION
logAppEvent('web_visit', {version: VERSION})
// console.info = function () {}
// console.warn = function () {}

let defaultLocale = window.localStorage.getItem('locale')
if (!defaultLocale){
    window.localStorage.setItem('locale', 'en')
} else {
    window.locale = defaultLocale
}

const head = createHead()

const app = createApp(App).use(i18n)

setTimeout(() => {
    app.use(store).use(router).use(head).mount('#app')
}, 2000)


store.dispatch('user/initialize')
