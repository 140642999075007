import { listenToAllShipments, checkDraftShipment, resetDraftShipment, updateDraftShipment, listenToEasyshipGlobalData, listenToRateQuote, createShipmentOrder, listenToShipmentOrder } from '../api'
import { Form } from '../shipForm'

export const shipment = {
  namespaced: true,
  state() {
    return {
      allShipmentList: [],
      draftShipment: null,
      pendingDraftLoad: false,
      currentQuoteError: '',
      currentQuoteRates: [],
      currentQuoteId: '',
      selectedCourier: null,
      shipmentOrder: null,

      currentStep: 1,
      localShipment: Form.createShipment,       // local shipment always has blank form as initial value
      pullStepOne: false,                  // request form ui force load local shipment data when true
      pullStepTwo: false,                  // request form ui force load local shipment data when true
      paidShipmentId: '',
      stepButtonLoading: false,

      // easyship Global data
      boxList: [],
      categoryList: []

    }
  },
  getters: {
    pickupShipmentList(state) {
      return state.allShipmentList.filter(shipment => shipment.pickUpState == 'requested').sort((A, B) => {
        return (
          new Date(A.pickupResponse.pickup.preferred_min_time).valueOf() -
          new Date(B.pickupResponse.pickup.preferred_min_time).valueOf()
        );
      });
    }
  },
  mutations: {
    setAllShipmentList(state, value) {
      if (!Array.isArray(value)) {
        console.error('VUEX/Shipment: setAllShipmentList can only accept array as payload')
        state.allShipmentList = []
      } else {
        state.allShipmentList = value
      }
    },
    setDraftShipment(state, value) {
      state.draftShipment = value || null
    },
    updateLocalShipmentField(state, data) {
      if (data && state.localShipment[data.key] !== undefined) {
        state.localShipment[data.key] = data.value
      }
    },
    setLocalShipment(state, value) {
      state.localShipment = value || null
    },
    setPendingDraftLoad(state, value) {
      state.pendingDraftLoad = !!value
    },
    requestLocalPull(state) {
      state.pullStepOne = true
      state.pullStepTwo = true
    },
    doneLocalPull(state,step) {
      switch (step) {
        case 1:
          state.pullStepOne = false
          break
        case 2:
          state.pullStepTwo = false
          break
        default:
          console.warn('VUEX/Shipment: doneLocalPull require step number between (1,2) provide')
      }
    },
    setCurrentStep(state, step) {
      switch (step) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          state.currentStep = step
          break
        default:
          console.warn('VUEX/Shipment: setCurrentStep require step number between (1,4) provide')
      }
    },
    setBoxList(state, boxList) {
      state.boxList = boxList
    },
    setCategoryList(state, categoryList){
      state.categoryList = categoryList
    },
    setStepButtonLoading(state, loading) {
      state.stepButtonLoading = !!loading
    },
    setCurrentQuoteRates(state, rates) {
      state.currentQuoteRates = rates
      state.selectedCourier = null
    },setCurrentQuoteError(state, error) {
      state.currentQuoteError = error || ''
    },
    setSelectedCourier(state, courier) {
      state.selectedCourier = courier || null
      state.currentQuoteError = ''
    },
    setShipmentOrder(state, shipment) {
      if (shipment) {
        shipment.error = shipment.error[window.locale || 'en']
      }
      state.shipmentOrder = shipment || null
    },
    clearFallbackRate(state) {
      if (state.shipmentOrder) {
        state.shipmentOrder.shipment.fallbackRate = null
      }
    },
    setCurrentQuoteId(state, quoteId) {
      state.currentQuoteId = quoteId || ''
    }
  },
  actions: {
    initialize (context, user) {
      console.info('VUEX: Start shipment initialization')
      listenToAllShipments(user, (shipmentList) => {
        context.commit('setAllShipmentList', shipmentList)
      })
      checkDraftShipment((draftShipment) => {
        context.commit('setDraftShipment', draftShipment.form)
        if (!draftShipment.isNew) {
          context.commit('setPendingDraftLoad', true)
        }
      })
      listenToEasyshipGlobalData((boxList, categoryList) => {
        if (boxList) {
          context.commit('setBoxList', boxList)
        }
        if (categoryList) {
          context.commit('setCategoryList', categoryList)
        }
      })
    },
    loadDraft (context) {
      context.dispatch('setLocalShipment', context.state.draftShipment)
      context.commit('setPendingDraftLoad', false)
      context.commit('requestLocalPull', true)
    },
    discardDraft (context) {
      resetDraftShipment((draftShipment) => {
        context.commit('setDraftShipment', draftShipment.form)
        context.dispatch('setLocalShipment', draftShipment.form)
        context.commit('setPendingDraftLoad', false)
        context.commit('requestLocalPull', true)
      })
    },
    submitFormStep (context,step) {
      context.commit('setStepButtonLoading', true)
      switch (step) {
        case 1:
          updateDraftShipment(context.state.localShipment, false, (success) =>{
            if (success) {
              
              context.commit('setCurrentStep', 2)
            }
            context.commit('setStepButtonLoading', false)
          })
          break;
        case 2:
          updateDraftShipment(context.state.localShipment, true, (success) =>{
            if (success) {
              context.commit('setCurrentStep', 3)
            } 
            context.commit('setStepButtonLoading', false)
          })
          break;
        case 3:
          if (!context.state.selectedCourier) {
            context.commit('setQuoteError', window.locale == 'en' ? "Please select a courier" : "请选择一个快递商")
            return
          }
          createShipmentOrder(context.state.selectedCourier.courier_id, (result) => {
            if (result.shipmentId) {
              context.dispatch('setShipmentOrder', result.shipmentId)
              context.commit('setCurrentStep', 4)
            } else {
              context.commit('setQuoteError', result.error[window.locale || 'en'])
            }
            context.commit('setStepButtonLoading', false)
          })
          break;
        default:
          console.error('VUEX/Shipment: submitForm require step number between (1,4) provide')
          break;
      }
    },
    updateLocalShipment (context, form) {
      for (const key in form) {
        if (form[key]) {
          context.commit('updateLocalShipmentField', {key: key, value: form[key]})
        }
      }
      // make sure quote listener are listening to quote id of updated local shipment
      context.dispatch('listenToRateQuote')
    },
    setLocalShipment (context, value) {
      context.commit('setLocalShipment', value)
      context.commit('payment/setChargedShipmentId', null, {root: true})
      context.commit('payment/setChargedShipment', null, {root: true})
      
      // make sure quote listener are listening to quote id of updated local shipment
      context.dispatch('listenToRateQuote')
    },
    setShipmentOrder(context, shipmentId) {
      // when shipment order id change, listen to new shipmentOrder
      if (shipmentId && context.state.shipmentOrderId != shipmentId){
        console.info('VUEX: shipment order id Changed, listen to new shipment order', shipmentId)
        listenToShipmentOrder(shipmentId, (shipmentOrder) => {
          context.commit('setShipmentOrder', shipmentOrder)
          context.dispatch('quote/listenToPickupQuote', shipmentOrder.shipment.courierId || '', {root: true})
        })
      }
    },
    listenToRateQuote (context) {
      // listen to the quoteId stored in localShipment, if not exist, use the quoteId of draft
      // check if quote exist
      if (!context.state.localShipment.quoteId) {
        let shipment = context.state.localShipment
        shipment.quoteId = context.state.draftShipment.quoteId || ''
        if (!shipment.quoteId) {
          console.error('No quoteId in localShipment and load quoteId from draft also failed')
          return
        }
        context.commit('setLocalShipment', shipment)
      }
      // check if need to update the listen target
      if (context.state.currentQuoteId != context.state.localShipment.quoteId) {
        // console.warn('VUEX: quoteId Changed, listen to new quote', context.state.localShipment.quoteId)
        context.commit('setCurrentQuoteId', context.state.localShipment.quoteId)
        listenToRateQuote(context.state.localShipment.quoteId, (quoteUpdate) => {
          if (quoteUpdate.rates.length > 0) {
            context.commit('setCurrentQuoteError', '')
            context.commit('setCurrentQuoteRates', quoteUpdate.rates)
          } else if (quoteUpdate.error) {
            context.commit('setCurrentQuoteError', quoteUpdate.error)
            context.commit('setCurrentQuoteRates', [])
          }
        })
      }
      
    }

  }
}