import { createStore } from 'vuex'
import { user } from './user'
import { payment } from './payment'
import { quote } from './quote'
import { shipment } from './shipment'

export default createStore({
  modules: {
    user, payment, quote, shipment
  },
  state() {
    return {
      globalWarning: ''
    }
  },
  mutations: {
    setGlobalWarning(state, message) {
      state.globalWarning = message || ''
    }
  }
})


// Access another module from one module (all module set namespaced to true)
// dispatch('namespace/action', {...}, {root:true})