import { listenToTopupPlan, topupCheckout, createChargeForShipment, listenToChargedShipment, listenToChargeHistory, listenToPaymentHistory, adminCharge, adminTopup } from "../api"
export const payment = {
  namespaced: true,
  state() {
    return {
      topupPlanList: [],
      topupResult: '',
      createChargeForShipmentError: '',
      chargedShipmentId: '',
      chargeShipmentLoading: false,
      chargedShipment: null,

      chargeHistory: [],
      paymentHistory: [],
      pendingCloseCreditChange: false
    }
  },
  getters: {
    fullCreditHistory(state) {
      return state.chargeHistory.concat(state.paymentHistory).sort((recordA, recordB) => {
        return recordB.created - recordA.created;
      });
    }
  },
  mutations: {
    setTopupPlanList(state, value) {
      if (!Array.isArray(value)) {
        console.error('VUEX/payment: setTopupPlanList can only accept array as payload')
        state.topupPlanList = []
      } else {
        state.topupPlanList = value
      }
    },
    setTopupResult(state, result) {
      result.warning = result.warning[window.locale || 'en']
      result.message = result.message[window.locale || 'en']
      state.topupResult = result
    },
    setCreateChargeForShipmentError(state, error) {
      state.createChargeForShipmentError = error || ''
    },
    setChargedShipmentId(state, shipmentId) {
      state.chargedShipmentId = shipmentId || ''
      console.info('VUEX/payment: setChargedShipmentId, set payment loading =>', false)
      state.chargeShipmentLoading = false
    },
    setChargeShipmentLoading(state, loading) {
      console.info('VUEX/payment: setChargeShipmentLoading =>', !!loading)
      state.chargeShipmentLoading = !!loading
    },
    setChargedShipment(state, shipment) {
      state.chargedShipment = shipment || null
    },
    setChargeHistory(state, chargeHistory) {
      state.chargeHistory = chargeHistory
    },
    setPaymentHistory(state, paymentHistory) {
      state.paymentHistory = paymentHistory
    },
    setPendingCloseCreditChange(state, pending) {
      state.pendingCloseCreditChange = !!pending
    }
  },
  actions: {
    initialize (context) {
      console.info('VUEX: Start payment initialization')
      listenToChargeHistory((chargeHistory) => {
        context.commit('setChargeHistory', chargeHistory || [])
      })
      listenToPaymentHistory((paymentHistory) => {
        context.commit('setPaymentHistory', paymentHistory || [])
      })
    },
    listenTopupPlan (context) {
      listenToTopupPlan((topupPlanList) => {
        context.commit('setTopupPlanList', topupPlanList)
      })
    },
    topupCheckout (context, plan) {
      topupCheckout(plan, (topupResult) => {
        context.commit('setTopupResult', topupResult)
      })
    },
    createChargeForShipment (context, data) {
      context.commit('setChargeShipmentLoading', true)
      context.commit('setCreateChargeForShipmentError', null)
      context.commit('setChargedShipment', null)

      createChargeForShipment(data.shipmentId, data.pickupOption, (result) => {
        if (result.hasError) {
          context.commit('setCreateChargeForShipmentError', result.error[window.locale || 'en'])
          context.commit('setChargedShipmentId', null)
          context.commit('shipment/setCurrentStep', 6, {root: true})
        } else {
          context.commit('setCreateChargeForShipmentError', null)
          context.commit('setChargedShipmentId', result.shipmentId)
          context.commit('shipment/setCurrentStep', 5, {root: true})
          listenToChargedShipment(result.shipmentId, (shipment) => {
            context.commit('setChargedShipment', shipment)
          })
        }
        // context.commit('shipment/setShipmentOrder', null, {root: true})
        context.commit('shipment/setCurrentQuoteRates', null, {root: true})
        context.commit('shipment/setCurrentQuoteError', '', {root: true})
      })
    },
    adminCharge(context, data) {
      adminCharge(data, () => {
        context.commit('setPendingCloseCreditChange', true)
      })
    },
    adminTopup(context, data) {
      adminTopup(data, () => {
        context.commit('setPendingCloseCreditChange', true)
      })
    }
  }
}