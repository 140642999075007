<template>
  <div>
    <div class="section wf-section blur no-margin-bottom" id="scroll-to-top">
      <div>
        <div class="logo-center">
          <img src="images/logo-loader-v1-100.gif" alt="" width="100" />
          <!-- <Plane :color="'#2359df'"></Plane> -->
          <div class="text-block-10 message">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LaunchScreen",
  data() {
    return {
      text: this.$t('LaunchScreen.brand')
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fix-card {
  position: fixed;
  z-index: 888;
  left: 0;
  right: 0;
}
.section {
  min-height: 0;
  margin-left: 0;
  padding-top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-center {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: 800;
  padding-right: 20px;
}

.row-center {
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.text-span-3 {
  font-size: 14px;
  font-weight: 600;
}
.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.text-block-15 {
  font-weight: 600;
}
.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.link-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  padding: 6px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #2359df;
  border-radius: 30px;
  background-color: #fff;
  font-family: Nunito, sans-serif;
  color: #2359df;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.text-block-12 {
  margin-right: 6px;
}
.text-block-13 {
  margin-right: 5px;
}

.text-block-14 {
  margin-right: 5px;
}

.text-block-15 {
  font-weight: 600;
}
.columns-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.column-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-right: 1px none #d6e2ff;
}
.main-term {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d6e2ff;
  text-align: center;
}
.single-term {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d6e2ff;
  text-align: center;
}
.text-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
}

.text-block-16.large {
  font-size: 24px;
  line-height: 1.5;
}
.tag {
  width: 70px;
  margin-top: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #07cccc;
  color: #fff;
  font-size: 12px;
}
.link-block-3 {
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: auto;
  padding: 10px;
}
.text-block-17 {
  font-weight: 500;
}

.text-block-18 {
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}

.text-block-18.large {
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 24px;
}

.column-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.column-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d6e2ff;
}
.sort-select {
  background-color: #2359df;
  color: white;
}
@media screen and (max-width: 991px) {
  .columns-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .link-block-3 {
    top: 0px;
    right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .section.blur {
    top: 0;
  }
  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .text-block-15 {
    margin-bottom: 5px;
  }
  .div-block-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .link-block-2 {
    margin-right: 10px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 479px) {
  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-9 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .link-block-2 {
    margin-bottom: 10px;
    padding-top: 6px;
  }
  .columns-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .column-16 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .main-term {
    width: 100%;
  }
  .link-block-3 {
    padding: 5px;
  }
}
</style>
