export default {
  "LeaveMessage": {
    "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要帮助或者为我们提供反馈？请在下方留言，我们会以最快速度回复。"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留言"])},
    "placeholder": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写留言"])}
    },
    "send_result": {
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，服务器开小差了。。请稍后再试 x.x"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们已经收到了您的留言，会以邮件形式尽快联系您 :))"])}
    },
    "warning": {
      "no_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还未登录，请您登录后为我们留言。"])},
      "no_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在输入框内填写您的问题或者反馈。"])},
      "too_frequent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，请求发送过于频繁，请稍后再试。"])}
    }
  },
  "AccountGeneral": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "email_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还未登录，请登录后操作。"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送中..."])},
    "timer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "秒后可重新发送"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])}
  },
  "UsersList": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户列表"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员"])},
    "credit_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "last_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一单距今"])},
    "last_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次登录距今"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载更多"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "search_place_holder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["搜索 ", _interpolate(_named("term"))])},
    "timer": {
      "day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["~ ", _interpolate(_named("day")), " 天"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 1 小时"])},
      "hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["~ ", _interpolate(_named("hour")), " 小时前"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 1 Min"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 1 年"])}
    }
  },
  "Signin": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以创建或管理订单"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎..."])}
  },
  "AskDraftBar": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您上次编辑的订单尚未完成"])},
    "start_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新开始"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续编辑"])}
  },
  "ChangeCredit": {
    "add_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入金额"])},
    "button": {
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中..."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])}
    },
    "current_credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["当前余额: $", _interpolate(_named("credit"))])},
    "edit_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变更余额"])},
    "email_address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["电子邮箱: ", _interpolate(_named("email"))])},
    "expect_credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["操作后新余额：$", _interpolate(_named("credit"))])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["姓名：", _interpolate(_named("name"))])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（可选）备注"])},
    "sub_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣款"])},
    "warning": {
      "loading_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载用户失败"])},
      "no_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入金额"])},
      "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户不存在"])},
      "number_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入大于零的数"])}
    }
  },
  "CreateAccount": {
    "button": {
      "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册中..."])},
      "start_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "to_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])}
    },
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册新账户"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户姓名"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "to_easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让快递变得轻松"])},
    "eula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户许可协议"])},
    "agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录或完成注册即代表你同意"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])}
  },
  "CreditHistory": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "all_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已显示所有记录"])},
    "expend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["支出: $", _interpolate(_named("amount"))])},
    "loading_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户加载中"])},
    "no_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到记录"])},
    "no_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户不存在"])},
    "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退款"])},
    "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["充值: $", _interpolate(_named("amount"))])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有疑问或需要帮助？"])}
  },
  "MyPickupsList": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件单号"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递商"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "dateAtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期及时间"])},
    "loadmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载更多"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "pickupID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件单号"])},
    "pickupaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件地址"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "searchby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的取件"])},
    "filter_option_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递商"])},
    "filter_option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
    "filter_option_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "filter_option_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
    "filter_option_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "filter_option_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄件人"])},
    "filter_option_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "filter_option_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运单号"])},
    "filter_option_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "filter_option_pickup_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件地址"])},
    "filter_option_pickup_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件单号"])}
  },
  "MyShipmentsList": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "IDdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号及日期"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成本(ES)"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递商"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递单"])},
    "loadmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载更多"])},
    "matches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["找到", _interpolate(_named("found")), "条符合条件的记录 （共", _interpolate(_named("total")), "条记录）"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中的"])},
    "optionalfilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多条件"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利润"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "searchby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件人"])},
    "shipmentsno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单总计"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运单号"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有寄件"])},
    "trackingno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运单号"])},
    "filter_option_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递商"])},
    "filter_option_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单号"])},
    "filter_option_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "filter_option_receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
    "filter_option_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "filter_option_sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄件人"])},
    "filter_option_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "filter_option_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运单号"])}
  },
  "Navbar": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助"])},
    "allshipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有寄件"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
    "newshipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄快递"])},
    "pickups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的取件"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有用户"])},
    "switch_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])}
  },
  "PopupLoading": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])}
  },
  "ReviewShipment": {
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
    "cancel-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单失败"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单已取消"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递商："])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品描述"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载(PDF)"])},
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自行寄放:"])},
    "empty-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，未找到该订单相关信息"])},
    "findlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找自寄点"])},
    "itemcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含物品(种类): "])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递单: "])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在取消订单，请稍候..."])},
    "nolocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无自寄信息"])},
    "notrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未请求该服务"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上门取件:"])},
    "pickupfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件费: "])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "shipfrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件地址: "])},
    "shipmentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递费: "])},
    "shipto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件地址: "])},
    "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "totalbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹总数: "])},
    "totalfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计:"])},
    "unitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单价"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运单追踪"])},
    "confirm_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认取消寄件吗？"])},
    "item_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位重量"])},
    "total_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总重:"])}
  },
  "ServiceOptions": {
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自行寄放"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收费"])},
    "fee-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估价格 $3.80, 实际价格由快递服务提供商决定"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费"])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在查询所选快递服务的可用时间"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自寄点"])},
    "provideby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商: "])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约取件"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选服务"])}
  },
  "SubmitFail": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回订单"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法创建订单，请稍后再试"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["糟糕! 无法为您创建订单，请稍后再试"])}
  },
  "SubmitSuccess": {
    "all-shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有寄件"])},
    "generating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成中"])},
    "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在等待快递服务商生成运单"])},
    "new-shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄快递"])},
    "note-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有寄件"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示快递单"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印快递单"])},
    "print-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意：您的快递服务商正在为您生成快递单。当我们收到快递单后，您便可以点击以下按钮查看，或者转到"])},
    "quick-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关链接"])},
    "send-package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄出包裹"])},
    "send-package-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备好您的包裹及快递单，并将其携带至服务网点或等待上门取件"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单成功"])}
  },
  "AllShipments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部订单 - G-快递"])}
  },
  "AllUsers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户管理 - G-后台"])}
  },
  "Credits": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额:"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史记录"])},
    "refund_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要申请退款或其他问题？"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户充值"])}
  },
  "EmptyState": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["糟糕！什么也没有"])}
  },
  "FormStep1": {
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 1"])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 2"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步 (2/4)"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(可选)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "please_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查/填写有问题的项"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人姓名"])},
    "residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅地址"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存中..."])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄件人姓名"])},
    "ship_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件地址"])},
    "ship_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件地址"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州/省"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤 1/4: 收件人信息"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "before_start_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用须知"])},
    "before_start_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 如果您的运单需要保险，请联系我们寻求帮助"])},
    "before_start_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 在使用过程中如需帮助，请随时联系我们并留下联系方式以便我们提供帮助"])},
    "before_start_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 订单提交后如果无法自动跳转至结果页面(持续加载)，请刷新页面并在所有寄件中查询订单状态"])},
    "before_start_button_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
    "before_start_button_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])}
  },
  "FormStep2": {
    "add_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增包裹"])},
    "add_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增物品"])},
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹"])},
    "box_dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹尺寸"])},
    "box_dimension_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(实际大于99的尺寸请填写99)"])},
    "box_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹类型"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "choose_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品描述"])},
    "dimension_descrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分快递服务商使用包裹尺寸来计算运费。\n请尽可能准确填写,以免产生额外费用。"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(可选)"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步(3/4)"])},
    "please_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查/填写有问题的项"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步(1/4)"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存中..."])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤 2/4: 包裹详情"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "unit_selling_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单价"])},
    "unit_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位重量"])}
  },
  "FormStep3": {
    "cheapest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低价"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运送时间"])},
    "dropoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自行寄放"])},
    "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉,没有找到适合的快递服务"])},
    "free_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费取件"])},
    "list_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在为您寻找方案"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步(4/4)"])},
    "paid_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付费取件"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步(2/4)"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
    "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选服务"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤 3/4: 选择快递服务"])},
    "total_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "please_select_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择快递方案"])}
  },
  "FormStep4": {
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经充值？您的新增数额将在一分钟之内可用"])},
    "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替代方案(推荐)"])},
    "choose_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新选择"])},
    "dropoff_pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自寄/上门取件"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运送时间"])},
    "list_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在创建您的订单"])},
    "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额不足(当前余额:"])},
    "new_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受替代方案"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(当前不可用)"])},
    "popup_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在处理您的订单..."])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步(3/4)"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中..."])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务:"])},
    "ship_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件地址"])},
    "ship_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件地址"])},
    "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快递费"])},
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，您所选的快递目前无法使用"])},
    "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤 4/4: 确认订单"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "your_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的选择"])},
    "your_courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的选择:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])}
  },
  "HelpCenter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助 - G-快递"])}
  },
  "MyAccount": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户 - G-快递"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出登录"])}
  },
  "MyPickups": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的取件 - G-快递"])}
  },
  "Home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 - G-快递"])}
  },
  "Intro": {
    "newshipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立刻寄件"])},
    "promo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丰富快递选择"])},
    "promo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轻松预约取件"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更快下单,<br>更优价格"])}
  },
  "LaunchScreen": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G-快递"])}
  },
  "NewShipment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄快递 - G-快递"])}
  },
  "PasswordResetSuccess": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以安全地关闭此页面并开始使用新密码登录"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码修改成功 - G-快递"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码修改成功！"])}
  },
  "PaymentFail": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付失败，请关闭本页面并重试"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["糟糕！无法处理您的付款"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付失败 - G-快递"])}
  },
  "PaymentSuccess": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的额度通常会在一分钟内可用。您可以关闭此页面以继续您的订单"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十分感谢，已收到您的付款！"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付成功 - G-快递"])}
  },
  "Topup": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择充值金额"])},
    "please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个充值金额"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值 - G-快递"])},
    "topup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "seller_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子额度由 AM CENTER HOME 销售"])}
  },
  "NoSolutionTips": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下为可能有用的建议: "])},
    "tip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["核对您填写的所有地址，确保其详细并准确"])},
    "tip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有些快递公司目前不支持单个订单包含多个包裹，您可以尝试将物品合并为单个包裹，或者使用多个订单来邮寄"])},
    "tip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试使用自定义尺寸或者尽可能小的快递箱来邮寄"])},
    "tip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试减少单个快递订单中物品的总重量，并视情况将其拆分为多个订单发送"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发消息给我们"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要一些帮助？"])}
  },
  "EULA": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户许可协议 - G-快递"])}
  },
  "PrivacyPolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策 - G-快递"])}
  }
}